import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const NOTICE = [
  {
    id: 1,
    type: 'AssociationNotice',
    text: '',
    label: '協会からのお知らせ'
  },
  {
    id: 2,
    type: 'JrNorthSchedule',
    text: 'JR北海道',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrEastSchedule',
    text: 'JR東日本',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrEastTokyoSchedule',
    text: 'JR東日本 [資格認定講習]',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrEastYokohamaSchedule',
    text: 'JR東日本 [10条在・運転]',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrEastHachiojiSchedule',
    text: 'JR東日本 [10条在・保守]',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrEastOmiyaSchedule',
    text: 'JR東日本 [10条幹・列車見張員]',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrWestSchedule',
    text: 'JR西日本',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrTokaiSchedule',
    text: 'JR東海',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrKyusyuSchedule',
    text: 'JR九州',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'SeibuSchedule',
    text: '西武鉄道',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'OdakyuSchedule',
    text: '小田急電鉄',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'TxSchedule',
    text: '首都圏新都市鉄道',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'TokyuSchedule',
    text: '東急電鉄',
    label: '保安講習会情報'
  },
  {
    id: 2,
    type: 'JrShikokuSchedule',
    text: 'JR四国',
    label: '保安講習会情報'
  },
  {
    id: 3,
    type: 'Notice',
    text: '',
    label: 'その他'
  }
];

const NoticeList = ({ year }) => {
  const data = useData();
  const notices = [];

  NOTICE.forEach(v => {
    data[`allWp${v.type}`].nodes.forEach(node => {
      notices.push({ ...v, value: node });
    });
  })

  notices.sort(function (a, b) {
    return (
      new Date(a.value.date) > new Date(b.value.date) ? -1 :
        1
    )
  });

  const yearNotice = (year === void 0) ? notices
    : notices.filter(values => {
      return values.value.date.slice(0, 4) === year
    })

  const htmlList = yearNotice.map((page,index) => {
    const { id, type, label, text, value } = page;
    return (
      <li key={index} className={`top_news_cat${id}`}>
        <span className="top_news_date">{value.date.slice(0, 10).replace(/-/g, '.')}</span>
        <span className={`label_base label0${id}`}>{label}</span>
        <span className="top_news_title">
          {
            id === 2 ?
              <Link to={`/training/${type.slice(0, -8).toLowerCase()}`}>
                講習会情報が更新されました。（{text}）
              </Link> :
              <Link to={value.uri}>
                {value.title}
              </Link>
          }
        </span>
      </li>
    )
  })

  return (
    <div>
      {htmlList}
    </div>
  )
}

function useData() {
  return useStaticQuery(graphql`
  query {
    allWpAssociationNotice {
      nodes {
        uri
        title
        date
      }
    }
    allWpNotice (filter: {MemberOnly: {memberonly: {ne: true}}}) { 
      nodes {
        uri
        title
        date
      }
    }
    allWpJrNorthSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrEastSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrEastTokyoSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrEastYokohamaSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrEastHachiojiSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrEastOmiyaSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrWestSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrTokaiSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrKyusyuSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpSeibuSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpOdakyuSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpTxSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpTokyuSchedule {
      nodes {
        content
        uri
        date
      }
    }
    allWpJrShikokuSchedule {
      nodes {
        content
        uri
        date
      }
    }
  }
  `);
}
export default NoticeList;

