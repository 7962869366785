import * as React from "react"
import NoticeList from '../components/noticeList.js'
import { Link } from "gatsby"
import Seo from "../components/seo"

const NewsListTemplate = ({ pageContext, location }) => {
  const { years, pageTitle } = pageContext;
  const yearMax = Math.max(...years)
  const yearListData = years.map((year) => {
    return {
      href: "/news_list/" + (yearMax !== year ? `${year}/` : ""),
      title: year
    }
  })

  const yearList = yearListData.map(({ href, title }) => {
    const className = location.pathname === href ?
      'current' : '';
    return (
      <li><a href={href} className={className}>{title}年</a></li>
    )
  })

  return (
    <>
      <Seo title="お知らせ一覧" />
      <div id="pageTitle">
        <h1>お知らせ一覧</h1>
      </div>
      <div className="breadClumb">
        <ul>
          <li>
            <Link to={"/"}>
              HOME
            </Link>
          </li>
          <li>お知らせ一覧</li>
        </ul>
      </div>
      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <div className="news_yearBtn">
              <ul>
                {yearList}
              </ul>
            </div>
            <div className="top_news_list newsPage">
              <ul>
                <NoticeList year={pageTitle}></NoticeList>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </>
  )

}
export default NewsListTemplate